import { Badge } from "../../@/components/ui/badge"
import posthog from 'posthog-js';
import {useEffect, useState} from 'react';
import clsx from 'clsx';
import { formatHostname, extractHostname } from './utils';
import {  Collapse, Box, MantineNumberSize } from '@mantine/core';
import { useDisclosure, useToggle } from '@mantine/hooks';
import styles from "../styles/Search.module.css";
import { ProductPreview } from './ProductPreview';
import { PageContext } from '../../contexts/pageContext';
import { useContext } from 'react';
import { AwesomeButton } from 'react-awesome-button';
import { FiShoppingCart } from 'react-icons/fi';
import { FaHeart, FaMessage, FaX } from 'react-icons/fa6';
import { ClarosSummary } from './ClarosSummary';
import { FaCartShopping, FaLink } from "react-icons/fa6";
import { FaCheck, FaExternalLinkSquareAlt } from "react-icons/fa";
import { FaShoppingCart } from 'react-icons/fa';
import { ProductButtons } from "../utils/ProductButtons";
import { LabelBadge } from "./LabelBadge"
import { ProductProCon } from "./ProductProCon"
import { ProductEndCard } from "./ProductEndCard";
import { BuyFromMerchant } from "../merchants/MerchantButton";
import { CheckoutButton } from "../checkout/CheckoutButton";
import { CheaperProductsModal } from "./modal/CheaperProductsModal";

const API_URL = process.env.NEXT_PUBLIC_API_URL;

const extractNumber = (str, pattern) => {
    const match = str.match(pattern);
    return match ? match[0] : null;
};

let numberPattern = /\d+(\.\d+)?|\.\d+/



export function ProductCardVertical(props) {
    const [opened, { toggle }] = useDisclosure(false);
    const [productInfo, setProductInfo] = useState({
        title: props.title, sku: props.sku, images: [props.image], price: props.price, rating: props.rating, detailPageURL: props.detailPageURL, taggedURL: props.link, description: "", category: [], delivery: [], bulletPoints: []
    });
    const context = useContext(PageContext)


    useEffect(() => {
        posthog.init(process.env.NEXT_PUBLIC_POSTHOG_API_KEY, { api_host: 'https://app.posthog.com' });
    }, []);


    async function fetchProductInfo() {
        await fetch(API_URL + '/getAmazonProduct', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ query : props.sku })
        })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            let res = data.results;
           //add keys not in prodctInfo to productInfo
            let newProductInfo = productInfo;
            newProductInfo.description = res.description;
            newProductInfo.category = res.category;
            newProductInfo.delivery = res.delivery;
            newProductInfo.bulletPoints = res.bulletPoints;
            setProductInfo(newProductInfo);
            // handle the response data here
        })
        .catch(error => {
            // handle any errors here
        });
    }



    {/* log click and go to link */}
    async function handleClick(event, link) {
        // Check if the click is coming from elements within ProductEndCard
        if (!event.currentTarget.contains(event.target.closest('.ignore-click'))) {
            posthog.capture('product click', {main: true, product: link, storeId: context.storeId, click_type: "preview"})
            if (context.isMainPage) {
                toggle();
            } else {
                window.open(link, "_blank");
            }
        }
    }

    const showBuy = () => {
        const userEmail = props.session?.user?.email;
        if (props.forceCheckout || userEmail === "owen@ioniccommerce.com" || userEmail === "ammarsethereumdomains@gmail.com" || userEmail === "justin.d.mccarty@gmail.com" || userEmail === "justin@ioniccommerce.com" || userEmail === "anish@claros.so") {
            return true;
        } else {
            return false;
        }
    }


    return (
            <>
            {opened && (
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto" >
                <ProductPreview
                    opened={opened}
                    toggle={toggle}
                    title={props.title}
                    price={props.price}
                    merchants={props.product.merchants}
                    image={props.image}
                    link={props.link}
                    rating={props.rating}
                    sku={props.sku}
                    description={productInfo.description}
                    category={productInfo.category}
                    delivery={productInfo.delivery}
                    bulletPoints={productInfo.bulletPoints}
                    aiSummary={props.aiSummary}
                    key={props.index}
                    handleProductSpecificSearch={props.handleProductSpecificSearch}
                    detailPageURL={props.detailPageURL}
                    session={props.session}
                    forceCheckout={props.forceCheckout}
                    productInfo={productInfo}
                    setCheckoutProduct={props.setCheckoutProduct}
                    redditRefs={props.redditRefs}
                    videos={props.videos}
                    gl={props.gl}
                    searchQuery={props.searchQuery}
                    askedQuestions={props.askedQuestions}
                />
                </div>
            )}
            <div onClick={(e) => handleClick(e, props.link )} className={clsx("flex flex-col flex-grow h-full overflow-visible mt-4 group relative h-full rounded-xl shadow-none tracking-wide leading-8 bg-white transition-colors duration-200 ease-in-out overflow-hidden cursor-pointer",
            { "ring-2 ring-indigo-600": props.index == 0, "border border-borderMain": props.index != 0 })} id="product-card"  >
                    <LabelBadge label={props.product.label} />
                    <div className="bg-cover bg-no-repeat bg-center p-4 h-56 md:h-64 min-w-32">
                        <div style={{ backgroundImage: `url(${props.image})`}} className="mx-auto h-full rounded-xl bg-contain bg-no-repeat bg-center object-contain" />
                    </div>
                    <div className="flex">

                    {Array.isArray(props.references?.sources) && props.references.sources.length > 0 && (
                        <>
                        <h4 className="pl-4 pr-2 text-gray-600 text-xs font-medium leading-6 uppercase tracking-wider">RECOMMENDED BY:</h4>
                        {props.references.sources.slice(0, 4).map((source, index) => (
                            <a href={source} target="_blank" rel="noopener noreferrer">
                                <img
                                    key={index}
                                    src={`https://www.google.com/s2/favicons?domain=${source}&sz=64`}
                                    alt="favicon"
                                    className='h-[20px] mr-2'
                                />
                            </a>
                        ))}
                        </>
                    )}
                    </div>
                    <h3 className=" p-4 text-base text-textMain line-clamp-3 tracking-normal">
                        {props.title}
                    </h3>
                    <div className="px-4 ignore-click">
                    <CheaperProductsModal product={props.product}
                        setCheckoutProduct={props.setCheckoutProduct}
                        forceCheckout={props.forceCheckout}
                        session={props.session}
                        className='px-2'
                        redditRefs={props.redditRefs}
                        videos={props.videos}
                        gl={props.gl}
                        searchQuery={props.searchQuery}
                        askedQuestions={props.askedQuestions}
                        handleProductSpecificSearch={props.handleProductSpecificSearch}
                        />
                    </div>
                    <ProductProCon pros={props.product.pros} cons={props.product.cons} />
                    <div className="flex-grow flex flex-col justify-end mx-2 ignore-click">
                        {/* <ProductEndCard rating={props.rating} reviews_count={props.reviews_count} price={props.price} detailPageURL={props.detailPageURL} link={props.link} session={props.session} forceCheckout={props.forceCheckout} productInfo={productInfo} setCheckoutProduct={props.setCheckoutProduct} /> */}
                        {props.product.merchants && Array.isArray(props.product.merchants) && props.product.merchants.map((merchant, index) => (
                        <BuyFromMerchant
                                  key={index}
                                  detailPageURL={merchant.links.original}
                                  link={merchant.links.tagged}
                                  price={merchant.price || null}
                                  toggleOpened={false}
                                  classic={true}
                                />
                            ))}

                            <BuyFromMerchant
                              key="current_merchant"
                              detailPageURL={props.detailPageURL}
                              rating={props.rating}
                              reviews_count={props.reviews_count}
                              link={props.link}
                              price={props.price}
                              toggleOpened={false}
                              classic={true}
                            />
                            { showBuy() &&
                            <div className="w-full border-t-2 border-gray-200 "></div>
                            }
                            <CheckoutButton
                              key="checkout_claros"
                              detailPageURL={props.detailPageURL}
                              link={props.link}
                              session={props.session}
                              forceCheckout={props.forceCheckout}
                              productInfo={productInfo}
                              setCheckoutProduct={props.setCheckoutProduct}
                              toggleOpened={false}
                              toggle={props.toggleCheckout}
                              classic={true}
                            />

                    </div>
            </div>
        </>
    )
}

