import React from 'react';
import axios from 'axios';
import posthog from 'posthog-js';
import {CompleteCheckoutRequest} from './IonicCommerceInterfaces';
import { updateProductLinkFallback } from './Affiliates';


// const resolveSSPALink = (product) => {
//   if (product.detailPageURL.includes('amazon.com/sspa/click')) {
//     product.detailPageURL = `https://www.amazon.com/dp/${product.sku}`;
//   }
// };

export const merchantMonetizeAndSwap = async (product, affiliate_link, searchQuery?: string) => {
  try {
    let { taggedLink, merchants } = await getMonetizedLink(product, searchQuery);
    // console.log({ ...product, taggedURL: taggedLink, merchants: merchants });
    return { ...product, taggedURL: taggedLink, merchants: merchants };
  } catch (error) {
    console.error('Error updating product link:', error);
    console.error('Do Fallback');
    updateProductLinkFallback(product, affiliate_link);
    return product; // Return original product if there's an error
  }
}

const COUNTRY_TAGS = {
  'de': 'getproducthel-21',
  'es': 'getproducth07-21',
  'uk': 'getproducth0e-21',
  'it': 'getproduct047-21',
  'ca': 'getproducth0b-20',
  'us': 'getproducth03-20',
  'au': 'getproducthel-22',
  'sg': 'getproducth05-22',
  'nl': 'getproducth05-21',
  'pl': 'getproduct04c-21',
  'be': 'getproducth0f-21',
  'se': 'getproducth0c-21',
  'fr': 'getproducth03-21'
};

export const addAmazonAffiliateTag = (url: string, gl?: string) => {
  try {
    // Check if it's an Amazon URL
    const urlObj = new URL(url);
    if (!urlObj.hostname.includes('amazon')) {
      return url;
    }

    // Determine country code from hostname or gl parameter
    let countryCode = 'us'; // Default to US
    if (gl) {
      countryCode = gl.toLowerCase();
    } else {
      const hostname = urlObj.hostname;
      if (hostname.includes('amazon.co.uk')) countryCode = 'uk';
      else if (hostname.includes('amazon.de')) countryCode = 'de';
      else if (hostname.includes('amazon.es')) countryCode = 'es';
      else if (hostname.includes('amazon.it')) countryCode = 'it';
      else if (hostname.includes('amazon.ca')) countryCode = 'ca';
      else if (hostname.includes('amazon.com.au')) countryCode = 'au';
      else if (hostname.includes('amazon.sg')) countryCode = 'sg';
    }

    const tag = COUNTRY_TAGS[countryCode] || COUNTRY_TAGS['us'];

    // Check if URL already has parameters
    const hasParams = url.includes('?');
    const hasTag = url.includes('tag=');

    if (hasTag) {
      return url; // Don't modify if already tagged
    }

    if (hasParams) {
      return `${url}&tag=${tag}`;
    }

    return `${url}?tag=${tag}`;

  } catch (error) {
    console.error('Error adding affiliate tag:', error);
    return url;
  }
};


export const getMonetizedLink = async (product, searchQuery?: string) => {
  try {
    // resolveSSPALink(product);
    // Get tagged link based on product source
    let taggedLink;
    if (product.detailPageURL?.includes('amazon')) {
      taggedLink = addAmazonAffiliateTag(product.detailPageURL);
    } else {
      // taggedLink = affiliate_fallback(product.detailPageURL);
      taggedLink = product.detailPageURL
    }
    const result = {
      originalLink: product.detailPageURL,
      taggedLink: taggedLink,
      merchants: null
    };
    return result;
  } catch (error) {
    console.error('Error posting product link:', error);
    throw error;
  }
};

// export const getMonetizedLink = async (product, searchQuery) => {

//   resolveSSPALink(product)
//   let tot = product.hasOwnProperty('search_engine');

//   const timestamp = new Date().getTime();
//   const requestBody = {
//   reference_id: `${window.location.href}`,
//   user_details: {
//       id: "1",
//       email: "test@example.com",
//       postal_code: "12345"
//   },
//   client_details: {
//       user_agent: "", // Getting the user agent from the browser
//       ip: "123.456" // This should be the actual IP address of the client, handled server-side or via a service
//   },
//   query: {
//       q: searchQuery // This should be dynamically set based on the user's actual query if applicable
//   },
//   product: {
//       title: product.title,
//       link: product.detailPageURL || product.url,
//       image_url: product.imageUrl,
//       identifiers: {
//           ...(tot ? { sku: product.sku } : { asin: product.sku })
//       },
//       price: {
//           amount: parseFloat(product.price.replace(/[$,]/g, ''))*100 ,
//           currency: "USD"
//       },
//       reference_id: `${window.location.href}`,
//   }
// };
// // console.log(requestBody)
// try {
//   const response = await axios.post(`/api/affiliates/monetizeLinkIonic`, requestBody, {
//   });
//   // console.log("Response", response)
//   // Parsing the response
//   const result = {
//     id: response.data.id,
//     originalLink: response.data.product.links.original,
//     taggedLink: response.data.product.links.tagged,
//     merchants: response.data.merchant_links
//   };
//   return result;
// } catch (error) {
//   console.error('Error posting product link:', error);
//   throw error;
// }
// };


export const startCheckoutWithLink = async (product) => {

  const url = `/api/affiliates/startCheckoutLink`;
  const data = {
    link: product.detailPageURL,
    success_url:"embedded",
    external_reference_id: `${window.location.href}`, // Optional
    external_metadata: {
      key1: 'value1',
      key2: 'value2'
    } // Optional
  };

  try {
    const response = await axios.post(url, data);
    // console.log("checkout data", response.data);
    if (response.status != 200) {
      posthog.capture("failed checkout",{link: url} )
      return null
    } else {
      return response.data
    }
  } catch (error) {
    posthog.capture("failed checkout", {link: url, "stage": "start"})
    console.error("error with starting checkout", error.response?.data || error.message);
    return null
  }
};


export const completeCheckout = async (checkoutInfo) => {
  const url = `/api/affiliates/completeCheckout`;
  try {
    const requestBody: CompleteCheckoutRequest = {
      checkout_token: checkoutInfo.checkout_token,
      billing_info: checkoutInfo.billing_info,
      shipping_info: checkoutInfo.shipping_info,
      payment_info: checkoutInfo.payment_info
    };
    const response = await axios.post(url, requestBody);
    // console.log(response.data);
    if (response.status != 200) {
      posthog.capture("failed checkout",{link: url, stage:"confirmation"} )
      return null
    } else {
      return response.data
    }
  } catch (error) {
    posthog.capture("failed checkout",{link: url} )
    console.error(error.response?.data || error.message);
    return null
  }
};



