import { useState, useEffect, useRef } from 'react';
import { ProductCardMin }from './ProductCardMin';
import { ProductCardMinMain } from './ProductCardMinMain';
import { ProductCardVertical } from './ProductCardVertical'
import { extractHostname } from './utils';
import { ChatMessage } from '../chat/ChatMessage';
import { PiCrosshairSimple, PiDotsThreeCircle, PiFlaskDuotone, PiRedditLogo, PiTable, PiTableLight, PiListMagnifyingGlassThin, PiYoutubeLogoThin, PiYoutubeLogo, PiYoutubeLogoFill, PiYoutubeLogoDuotone, PiTableThin, PiYoutubeLogoLight } from "react-icons/pi"
import {merchantMonetizeAndSwap, completeCheckout, startCheckoutWithLink} from "../utils/Monetize"
import { PageContext } from '../../contexts/pageContext';
import { useContext } from 'react';
import posthog from 'posthog-js';
import { MoreProductsModal } from './modal/MoreProductsModal';
import { CheckoutModal } from '../checkout/CheckoutModal';
import { useDisclosure, useToggle } from '@mantine/hooks';
import {updateProductLinkFallback} from '../utils/Affiliates';

const API_URL = process.env.NEXT_PUBLIC_API_URL;
function filterDomains(items, domains) {
  return items.filter((item) => {
    const domain = extractHostname (item.detailPageURL);
    return domains.includes(domain);
  })
}

function convertPrice(price) {
  //if number return number
  if (typeof price === 'number') {
    return price;
  }
  //if string remove $ and ,
  return parseFloat(price.replace(/[$,]/g, ''));
}


function sortLowToHigh(items) {
    return items.sort((a, b) => convertPrice(a.price) - convertPrice(b.price));
}

function sortHighToLow(items) {
    return items.sort((a, b) => convertPrice(b.price) - convertPrice(a.price));
}

function sortRatingsLowToHigh(items) {
    return items.sort((a, b) => a.rating - b.rating);
}

function sortRatingsHighToLow(items) {
    return items.sort((a, b) => b.rating - a.rating);
}

function sortProductsClarosOrder(items) {
  //return the products such that the first one is the one with the ai summ
  return items.sort((a, b) => (b.aiSummary ? 1 : -1));
}



function ProductCarousel({ products, sort, isMobile, handleProductSpecificSearch, domains, last, message, retryChat, idx, redditRefs, setFeedbackOpen, setProductDescriptionMap, sessionId, videos, askedQuestions, searchQuery, object, gl, session, forceCheckout }) {

  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [isUserScrolling, setIsUserScrolling] = useState(true);
  const [userScrollPosition, setUserScrollPosition] = useState(0);
  const [sortedProducts, setSortedProducts] = useState(products || []);
  const containerRef = useRef(null);
  const context = useContext(PageContext)
  const AFFILIATE_LINK = context.affiliateLink;

  const [openedCheckout, { toggle }] = useDisclosure(false);
  const [checkout, setCheckout] = useState(null);
  const [checkoutProduct, setCheckoutProduct] = useState(null)
  const [updatedCheckout, setUpdatedCheckout] = useState(null);
  const [confirmCheckout, setConfirmCheckout] = useState(false);
  const [checkoutSuccess, setCheckoutSuccess] = useState(false);
  const [checkoutMessage, setCheckoutMessage] = useState('');

  useEffect(() => {
      if (checkoutProduct) {
        // console.log("start checkout product")
        setUpdatedCheckout(null)
        setConfirmCheckout(false)
        setCheckoutSuccess(false)
        setCheckout(null)
        setCheckoutMessage('Initiating Checkout')
        startCheckout()
        toggle()
      }
  }, [checkoutProduct]);

  useEffect(() => {
    // console.log("finishing checkout", updatedCheckout)
    if (confirmCheckout && updatedCheckout) {
      finishCheckout(updatedCheckout);
    }
  }, [confirmCheckout]);


  async function startCheckout() {
      posthog.capture('checkout start', {main: true, product: checkoutProduct.detailPageURL, storeId: context.storeId})
      // console.log("starting checkout", checkoutProduct)
      let details = await startCheckoutWithLink(checkoutProduct)
      // console.log("checkout respnose",details)
      setCheckoutMessage('')
      if (details) {
        setCheckout(details)
        // console.log("setting checkout iframe")
      } else {
        //if error in doing embedded checkout
        const urlToOpen = checkoutProduct.taggedURL || checkoutProduct.detailPageURL;
        // console.log("Opening site instead of embedded checkout", urlToOpen);
        window.open(urlToOpen, '_blank');
      }
  }

  async function finishCheckout(checkoutInfo) {
    posthog.capture('checkout finish', {main: true, product: checkoutProduct.detailPageURL, storeId: context.storeId})
    let checkedOut = await completeCheckout(checkoutInfo)
    // console.log("Checkout confirmation returns", checkedOut)
    if (checkedOut.order_status === "confirmed") {
      setCheckoutSuccess(true);

    }
  }



  useEffect(() => {
    const container = containerRef.current;

    const handleScroll = () => {
        const newScrollPosition = container.scrollLeft;
        const maxScrollPosition = container.scrollWidth - container.clientWidth;

        setUserScrollPosition(newScrollPosition);
        setCanScrollLeft(newScrollPosition > 0);
        setCanScrollRight(newScrollPosition < maxScrollPosition);
    };
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

  }, [products]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.scrollTo({ left: scrollPosition, behavior: 'smooth' });
    }
  }, [scrollPosition]);



  useEffect(() => {
    const shouldUpdateLinks = Math.random() < 1;

    const updateLinksForFirstThreeProducts = async (productsToUpdate) => {
      const updatedProducts = await Promise.all(productsToUpdate.map(async (product, index) => {
        if (index < 3) {
          return await merchantMonetizeAndSwap(product, AFFILIATE_LINK, searchQuery);
        }
        return product;
      }));

      return updatedProducts;
    };

    if (products && shouldUpdateLinks) {
      updateLinksForFirstThreeProducts(products).then(updatedProducts => {
        setSortedProducts(updatedProducts);
      });
    } else {
      products.forEach(product => {
        updateProductLinkFallback(product, AFFILIATE_LINK);
      });
      setSortedProducts(products);
    }
  }, [products]);

  return (
    <div className="relative  max-w-[100vw]">

      {openedCheckout && (
                <div className="fixed inset-0 z-[10000] w-screen overflow-y-auto" >
                <CheckoutModal
                  checkoutProduct={checkoutProduct}
                  opened={openedCheckout}
                  toggle={toggle}
                  setUpdatedCheckout={setUpdatedCheckout}
                  updatedCheckout={updatedCheckout}
                  setConfirmCheckout={setConfirmCheckout}
                  checkoutSuccess={checkoutSuccess}
                  checkout={checkout}
                  setCheckout={setCheckout}
                  checkoutMessage={checkoutMessage}
                  setCheckoutMessage={setCheckoutMessage}
                />
                </div>
      )}


      { sortedProducts.length > 0  && (
      <>
      {/* <div className='flex flex-col justify-start'>
          <ProductCardMinMain
            key={sortedProducts[0].id}
            product={sortedProducts[0]}
            image={sortedProducts[0].imageUrl}
            price={sortedProducts[0].price}
            title={sortedProducts[0].title}
            link={sortedProducts[0].taggedURL ?  sortedProducts[0].taggedURL : sortedProducts[0].detailPageURL}
            detailPageURL={sortedProducts[0].detailPageURL}
            rating={sortedProducts[0].rating}
            sku={sortedProducts[0].sku}
            inlineProduct={true}
            bestSeller={sortedProducts[0].best_seller}
            amazonsChoice={sortedProducts[0].is_amazons_choice}
            reviews_count={sortedProducts[0].reviews_count}
            source={sortedProducts[0].source}
            index={0}
            handleProductSpecificSearch={handleProductSpecificSearch}
            aiSummary={sortedProducts[0].aiSummary}
            checkoutProduct={checkoutProduct}
            setCheckoutProduct={setCheckoutProduct}
            forceCheckout={forceCheckout}
            session={session}
          />
          <div className="">

          </div>
      </div> */}

      {/* <MoreInfoTabs redditRefs={redditRefs} videos={videos} gl={gl}  sortedProducts={sortedProducts} searchQuery={searchQuery} askedQuestions={askedQuestions} /> */}
      <div className={`grid grid-cols-1 md:grid-cols-3 gap-2`} ref={containerRef}>
        {sortedProducts.slice(0, 3).map((product, index) => (
          <ProductCardVertical
            key={product.id}
            product={product}
            image={product.imageUrl}
            price={product.price}
            title={product.title}
            link={product.taggedURL}
            detailPageURL={product.detailPageURL}
            rating={product.rating}
            sku={product.sku}
            inlineProduct={true}
            bestSeller={product.best_seller}
            amazonsChoice={product.is_amazons_choice}
            reviews_count={product.reviews_count}
            source={product.source}
            references={product.references}
            index={index}
            handleProductSpecificSearch={handleProductSpecificSearch}
            aiSummary={product.aiSummary}
            setCheckoutProduct={setCheckoutProduct}
            forceCheckout={forceCheckout}
            session={session}
            className='px-2'
            redditRefs={redditRefs}
            videos={videos}
            gl={gl}
            searchQuery={searchQuery}
            askedQuestions={askedQuestions}
            openedCheckout={openedCheckout}
            toggleCheckout={toggle}
          />
        ))}
      </div>
      <div className="mx-auto py-4">
        <MoreProductsModal products={sortedProducts}
            setCheckoutProduct={setCheckoutProduct}
            forceCheckout={forceCheckout}
            session={session}
            className='px-2'
            redditRefs={redditRefs}
            videos={videos}
            gl={gl}
            searchQuery={searchQuery}
            askedQuestions={askedQuestions} />
      </div>
      <div className='flex  w-3/12 w-full justify-start  text-xl pt-12 text-textMain tracking-normal'>
          <PiCrosshairSimple className='mr-1 mt-[2px]' size={25}/> Top Pick: {sortedProducts[0].title}
      </div>
      <ChatMessage
              isBot={true}
              message={message}
              isCurr={true}
              retry={retryChat}
              idx={idx}
              sessionId={sessionId}
              setFeedbackOpen={setFeedbackOpen}
              setProductDescriptionMap={setProductDescriptionMap}
              redditRefs={redditRefs}
              products={sortedProducts}
              />
      </>
      )}
    </div>

  );
}

export default ProductCarousel;