import { ChatInputBar } from './ChatInputBar';
import React, { useState, useEffect, useRef } from 'react';
import styles from "../../styles/Search.module.css";
import posthog from 'posthog-js';
import { useCookies } from 'react-cookie'
const COOKIE_NAME_ID = 'product-help-chat-gpt3'
const COOKIE_NAME_COUNTRY = 'product-help-chat-gpt3-country'
const API_URL = process.env.NEXT_PUBLIC_API_URL;
import { PageContext } from '../../contexts/pageContext';
import { useContext } from 'react';
import ProductCarouselMin from '../products/ProductCarouselMin';
import { translationsDone, translationsLastChat } from '../utils/translations';
import { FollowUpQuestionCard } from './FollowUpQuestionCard';
import { ShareTopic } from '../ShareTopic';
import { ProcessSection } from '../ProcessSection';
import { ProcessSectionProgress } from '../ProcessSectionProgress';
import { ShareButton } from '../ShareButton';
import { followUpQuestions } from './utils/followUpQuestions';
import { generateChat } from './utils/generateChat';
import PhotoMini from '../photos/photoMini';
import { MoreProductsModal } from '../products/modal/MoreProductsModal';

export function ChatPaneMin(props: any) {
    const [query, setQuery] = useState(props.query || "")
    const [undoLog, setUndoLog] = useState(false)
    const [numUndo, setNumUndo] = useState(0)
    const [qapair, setQapair] = useState(props.qapair || [])
    const [questionsList, setQuestionsList] = useState( props.questionsList || [] )

    const [allOptions, setAllOptions] = useState(() => {
        if (props.chats && Array.isArray(props.chats)) {
          return props.chats.map(chat => chat.options || []);
        } else {
          return [];
        }
    });
    const [options, setOptions] = useState(props.options || [])
    const [isDone, setIsDone] = useState(false); // Add this line
    const [loaderVisible, setLoaderVisible] = useState(false)
    const [inlineProducts, setInlineProducts] = useState(props.inlineProducts || [])
    const bottomRef = useRef<HTMLDivElement>(null);
    const botRef = useRef<HTMLDivElement>(null);
    const [cookies, setCookie, removeCookie] = useCookies([COOKIE_NAME_ID, COOKIE_NAME_COUNTRY])
    const [searchQuery, setSearchQuery] = useState('');
    const [object, setObject] = useState(null);
    const [userScrolled, setUserScrolled] = useState(false);
    const chatContainerRef = useRef(null);


    const [uid, setUser] = useState(null)
    const [sortType, setSortType] = useState('');

    const CHATS_BEFORE_RECS_START = 0

    const context = useContext(PageContext)
    const [webRefs, setWebRefs] = useState([]);
    const [sourceRefs, setSourceRefs] = useState([]);
    const inputRef = useRef(null);
    const [progressMessages, setProgressMessages] = useState( props.progressMessages || [] );
    const [domainFilter, setDomainFilter] = useState([]);

    const [productDescriptionMap, setProductDescriptionMap] = useState({})
    const [videos, setVideos] = useState([])
    const [influencerRecs, setInfluencerRecs] = useState([])
    const [photo, setPhoto] = useState( props.photo || null)

    const [filters, setFilters] = useState([])

    const [desc, setDesc] = useState([])


    const getYT4Product = async (product) => {
        try {
            const response = await fetch(`${API_URL}/getYT4Product`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ product: product, query: searchQuery }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setVideos( prevVideo => [...prevVideo , data.videos])
            return data.videos;
        } catch (error) {
            console.error("Failed to fetch YouTube videos for product:", product, error);
            return [];
        }
    };


    function reload() {
        if (window.location.href.includes('/threads')) {
            // Navigate to the origin (base URL)
            window.location.href = window.location.origin;
        } else {
            // Reload the current page
            window.location.reload();
        }
    }

    function retryChat() {
        if (qapair.length === 1) {
            // Reload the page
            reload()
        } else {
            // Remove the last chat from qapair
            setUndoLog(true)
            setNumUndo(numUndo + 1)

            const newQapair = qapair.slice(0, -1);
            setOptions( allOptions[ newQapair.length -1 ] )
            setQapair(newQapair);
            // Remove the latest set of products from inlineProducts
            if (inlineProducts.length > 0) {
                const newInlineProducts = inlineProducts.slice(0, -1);
                setInlineProducts(newInlineProducts);
            }
            // Reset the query and any other necessary state variables
            setQuery('');
            // Reset other state variables as needed
        }
    }

    const sanitizePrice = (priceStr) => {
        if (typeof priceStr !== 'string') {
            priceStr = priceStr.toString();
        }
        if (!priceStr) return 0; // If undefined or empty
        const sanitized = priceStr.replace(/[^\d.]/g, '');  // Removes all non-numeric characters except for decimal points
        return parseFloat(sanitized);
    }



    const getMinMaxPriceForGroup = (products) => {
        let min = Infinity;
        let max = -Infinity;
        if (!isDone) {
            if (products) {
                products.forEach(product => {
                    const price = sanitizePrice(product.price);
                    if (price < min) min = price;
                    if (price > max) max = price;
                });
            }

        }

        return [min, max];
    }

    function updateRecs(rec) {
        props.updateRecs(rec)
    }

    useEffect(() => {
        if (!chatContainerRef.current) return; // Exit if the ref is not attached

        const handleScroll = () => {
            const isNearBottom = chatContainerRef.current.scrollHeight - chatContainerRef.current.scrollTop <= chatContainerRef.current.clientHeight + 10;

            setUserScrolled(!isNearBottom);
        };

        chatContainerRef.current.addEventListener('scroll', handleScroll);
        return () => {
            if (chatContainerRef.current) { // Also check here to avoid potential issues
                chatContainerRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);


    function showRecs() {
        return true
        return (qapair.length >=  (CHATS_BEFORE_RECS_START) && !isDone)
        // return (qapair.length >=  (CHATS_BEFORE_RECS_START) && !isDone && inlineProducts[inlineProducts.length -1] && inlineProducts[inlineProducts.length -1].length > 0)
    }

    function showSortAndBudget() {
        return inlineProducts[inlineProducts.length -1] && inlineProducts[inlineProducts.length -1].length > 0
    }

    async function handleSkipToRecs() {
        submitSearch('Please skip this question', qapair, props.topic, true);
    }

    useEffect(() => {
        // console.log("questions", props.askedQuestions)
    }, [props.askedQuestions]);

    async function handleProductSpecificSearch(query) {
        // console.log("Props askedQuestions:", props.askedQuestions);

        let questionsAnswers = [ ...props.askedQuestions, [['What else can I assist you with?', query]] ];

        // console.log("Questions and Answers:", questionsAnswers);
        submitSearch(query, qapair, props.topic, true, questionsAnswers);
    }


    async function handlechat(query, qapair, options, response, recs, topic, object, sessionId, storeId, undo=false, searchQuery, progressMessages, questions, askedQuestions){
        let timeStamp = Date.now()
        let user = uid || ''
        posthog.capture('chat generated', {
            query: query,
            history: qapair,
            searchQuery: searchQuery,
            options: options,
            response: response,
            recs: recs,
            topic: topic,
            sessionId: sessionId,
            timeStamp: timeStamp,
            storeId: storeId,
            undo: undo,
            askedQuestions: askedQuestions,
            user: user
        })
        await fetch("/api/telemetry/log", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: query,
                history: qapair,
                options: options,
                response: response,
                recs: recs,
                topic: topic,
                object: object,
                uid: uid,
                timestamp: timeStamp,
                sessionId: sessionId,
                storeId: storeId,
                searchQuery: searchQuery,
                progressMessages: progressMessages,
                questions: questions,
                askedQuestions: askedQuestions,
                undo: undo,
                numUndo: numUndo,
                url: window.location.href
            }),
        })
        setUndoLog(false)
        setNumUndo(0)
    }

    useEffect(() => {
        setDomainFilter([])
    }, [inlineProducts])


    async function initChat(topic) {
        if (topic != ""){
            setLoaderVisible(true)
            await submitSearch(query, qapair, topic, true, props.askedQuestions )
            setLoaderVisible(false)
            if (! props.uid) {
                props.setShowAuthPrompt(true)
            }

        }
    }

    useEffect(() => {
        if (props.topicSelected && qapair.length == 0) {
            initChat(props.topic)
        }
    }, [props.topicSelected])

    async function generateRecs(query, qapairpassedin, topic, askedQuestionsPassed, skipToRecs) {
        setProgressMessages([...progressMessages, [{ message: 'Thinking...', value: 10, results: [] }]])
        let returned_questions = []
                const formData = new FormData();
                if (photo) {
                    formData.append('photo', photo);
                }
                // Append other data as part of the formData
                formData.append('data', JSON.stringify({
                    topic: topic,
                    history: [...qapairpassedin, [query]],
                    query: query,
                    store_id: context.storeId,
                    gl: cookies[COOKIE_NAME_COUNTRY] || "US",
                    language: context.language,
                    psearch: searchQuery,
                    skipToRecs: true,
                    profile: props.profile,
                    askedQuestions: askedQuestionsPassed,
                    id: context.id,
                    affix: context.affix ? context.affix : null,
                    sources: context.sources ? context.sources : null,
                    searchPromptModifier: context.searchPromptModifier ? context.searchPromptModifier : "",
                    searchReminder: context.searchReminder ? context.searchReminder : "",
                    customFilters: props.customFilters
                }));
                const shopEverythingResponse = await fetch(context.shopUrl, {
                    method: 'POST',
                    body: formData // No headers needed, as the browser will set the correct `Content-Type`
                });
                const shopEverythingData = shopEverythingResponse.body
                let done = false;
                let lastMessage = '';
                let rekey = null
                const reader = shopEverythingData.getReader();
                const decoder = new TextDecoder();
                let video_sources = videos;
                let influencer_recs = influencerRecs;


                let updatedMessages = []
                while (!done) {
                    const { value, done: doneReading } = await reader.read();
                    done = doneReading;
                    lastMessage += decoder.decode(value, { stream: true }); // Accumulate data in case it's split across chunks

                    if (done || lastMessage.endsWith('\n')) { // Check if the stream is done or if a complete message has been received
                        try {
                            const parsedMessage = JSON.parse(lastMessage.trim()); // Trim any trailing newlines
                            rekey =  {
                                message: parsedMessage.status,
                                value: parsedMessage.progress,
                                results: parsedMessage.results
                            };

                            // Update the state of progressMessages by appending to the last array in the array of arrays with the new incoming messages rekey
                            setProgressMessages(currentMessages => {
                                // Use currentMessages to calculate the new state
                                let newMessages = [...currentMessages];
                                newMessages[newMessages.length - 1] = [
                                  ...newMessages[newMessages.length - 1],
                                  rekey
                                ]; // Create a new array with the updated messages
                                updatedMessages = newMessages;
                                return newMessages;
                              });

                            if (parsedMessage.status && parsedMessage.status.includes("Watching")) {
                                setVideos(prevRefs => [ ...prevRefs, parsedMessage.results]);
                                video_sources = [ ...videos, parsedMessage.results];
                                influencer_recs = [ ...influencerRecs, parsedMessage.recs]
                            }
                            if (parsedMessage.streamEnded) {
                                break; // Exit the loop since the stream has concluded
                            }
                        } catch (error) {
                            console.error('Error parsing JSON:', error, 'from jsonString:', lastMessage);
                        }
                        lastMessage = ''; // Reset for the next message
                    }
                }

        let parsedResponse = JSON.parse(lastMessage) // Assuming response.data is a JSON string; otherwise, you can skip this step
        let returnedSearchQuery = parsedResponse.searchQuery;
        let returnedProducts = parsedResponse.results;

        // getYT4Product(returnedProducts[0])
        const readingMessages = updatedMessages[updatedMessages.length - 1].filter(message => message.message.includes("Reading"));
        const recommendations = readingMessages.flatMap(message => message.results.filter(result => result.recommendations));

        // Ensure each object is unique by link attribute and keep one with a "content" or "comment" key present if present
        const uniqueRecommendations = recommendations.reduce((acc, current) => {
            const x = acc.find(item => item.link === current.link);
                if (!x) {
                    acc.push(current);
                } else if (current.content || current.comment) {
                    acc = acc.filter(item => item.link !== current.link);
                    acc.push(current);
                }
                return acc;
        }, []);

        let sources = [...sourceRefs, uniqueRecommendations];
        setSourceRefs(sources);

        let web_recs = parsedResponse.web_recs || [];
        let object = parsedResponse.object || "";
        let filters = parsedResponse.serpFilters || [];

        // Call getQuestions asynchronously within generateRecs
        getQuestions(query, returnedSearchQuery, qapairpassedin, topic, object, askedQuestionsPassed, context.language).then(() => {
            console.log("Questions updated");
        });

        await setSearchQuery(returnedSearchQuery)
        setWebRefs(web_recs)
        setObject(object)
        // setProgressMessages({message: "Looking across Amazon...", value: 66})

        await setFilters(filters)
        await setInlineProducts(prevProducts => [...prevProducts, returnedProducts]);

        // console.log("object",object)
        const chatRes = await generateChat(query, qapairpassedin, topic, returnedProducts, web_recs, props.numberOfInlineProducts, returnedSearchQuery, skipToRecs || showRecs(), askedQuestionsPassed, sources, video_sources, object, context, cookies[COOKIE_NAME_ID], props.profile, setQapair, setLoaderVisible, setDesc)

        setQapair(chatRes.newHistory)
        setOptions(chatRes.newOptions)
        setAllOptions([...allOptions, chatRes.newOptions])

        props.setPassedProducts(returnedProducts)

        return {
            chatRes: chatRes,
            updatedMessages: updatedMessages,
            returnedProducts: returnedProducts,
            object: object,
            searchQuery: returnedSearchQuery
        }

    }

    const handleScroll = () => {
        setUserScrolled(true);
      };



    useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
    }, []);

    useEffect(() => {

        if (!userScrolled) {
            if (botRef.current){
                if (false && window.CSS && CSS.supports('scroll-behavior: smooth')) {
                    botRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

                    const topPosition = botRef.current.getBoundingClientRect().top + window.pageYOffset;
                    window.parent.postMessage({
                       type: "iframeScroll",
                       top: topPosition,
                    }, "*");

                  } else {
                    // Fallback for browsers that do not support smooth scrolling

                    const topPosition = botRef.current.getBoundingClientRect().top + window.pageYOffset;
                    window.scrollTo({ top: topPosition, behavior: 'smooth' });
                    window.parent.postMessage({
                       type: "iframeScroll",
                       top: topPosition,
                    }, "*");

                  }
            } else {
                // TODO: test this and see how ppl like it
                if (false && window.CSS && CSS.supports('scroll-behavior: smooth')) {
                    bottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

                    const topPosition = bottomRef.current.getBoundingClientRect().top + window.pageYOffset;
                    window.parent.postMessage({
                       type: "iframeScroll",
                       top: topPosition,
                    }, "*");
                  } else {
                    // Fallback for browsers that do not support smooth scrolling


                    const topPosition = bottomRef.current.getBoundingClientRect().top + window.pageYOffset;
                    window.scrollTo({ top: topPosition, behavior: 'smooth' });
                    window.parent.postMessage({
                       type: "iframeScroll",
                       top: topPosition,
                    }, "*");
                }
            }
        }
    }, [qapair]);

    async function getQuestions(query, searchQuery, history, topic, object, askedQuestions, language) {
        let questions = followUpQuestions(query, searchQuery, history, topic, object, askedQuestions, language, setQuestionsList);
        // let values = await Promise.all([questionsPromise]); // Use await here
        // let questions = values[0];
        setQuestionsList(questions);
    }

    async function submitSearch(query, qapairpassedin, topic, skipToRecs = false, askedQuestions=[]) {

        if(Object.values(translationsDone).includes(query)) {
            setIsDone(true);
            // You can set your prewritten message here. Assuming setQapair is the function to set chat messages.
            setQapair([...qapairpassedin, [query, translationsLastChat[context.language]]]);


            let timeStamp = Date.now()
            posthog.capture('chat done', {
                topic: topic,
                sessionId: props.sessionUUID,
                timeStamp: timeStamp,
                storeId: context.storeId
            })

            if (!userScrolled) {
                if (bottomRef && bottomRef.current) {
                    // Check if the browser supports smooth scrolling
                    if (window.CSS && CSS.supports('scroll-behavior: smooth')) {
                    bottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    } else {
                    // Fallback for browsers that do not support smooth scrolling

                    const topPosition = bottomRef.current.getBoundingClientRect().top + window.pageYOffset;
                    window.scrollTo({ top: topPosition, behavior: 'smooth' });
                    }
                }
            }

            setTimeout(() => {
                props.setFeedbackOpened(true); // Assuming that props.setFeedbackOpened is a function to open the feedback
            }, 3000);

            //we need to call handleChat with correct stuff so it get's logged.
            return;
        }
        setLoaderVisible(true)

        let res = await generateRecs(query, qapairpassedin, topic, askedQuestions, skipToRecs);

        // getQuestions(query, res.searchQuery, history, topic, res.object, askedQuestions, context.language);

        handlechat(query, res.chatRes.newHistory, res.chatRes.newOptions, res.chatRes.lastMessage, res.returnedProducts, topic, res.object,  props.sessionUUID, context.storeId, undoLog, res.searchQuery, res.updatedMessages, [], askedQuestions)
        return
    }

    return (
        <div className='px-4 lg:px-0 max-w-screen lg:max-w-[1000px] mx-auto w-screen'>
                {/* <LoadingOverlay visible={loaderVisible} /> */}
            <div className="" >
                <div className={styles.form} style={{ textAlign: 'center', paddingLeft: "10%", paddingRight: "5%" }}>
                </div>

            {props.topic && (
                <>
                <div className='flex items-center justify-between  mt-4'>
                    <div className="flex justify-between py-4 text-lg md:text-xl lg:text-xl max-w-full">
                        <div className="items-center flex  pb-1 text-textMain line-clamp-1">
                        {props.topic}
                        </div>
                    </div>
                    <ShareButton sessionId={props.sessionUUID} isMobile={props.isMobile}/>

                </div>
                <div className='flex items-center justify-between'>
                    <PhotoMini photo={photo} />
                </div>

                    <div className='flex'>
                    {props.askedQuestions && props.askedQuestions[0] && props.askedQuestions[0].map((question) => (
                        <div className="flex justify-between py-1 max-w-full">
                            <div className="items-center flex bg-indigo-600 text-white py-2 px-4 mr-2 rounded-full text-sm">
                                {question[1]}
                            </div>
                        </div>
                    )
                    )}
                    </div>
                </>
            )}
            {
                (inlineProducts && qapair) && (
                    qapair.map((pair, idx) => {
                        // const [minPrice, maxPrice] = getMinMaxPriceForGroup(inlineProducts[idx]);
                        let sortedProducts = []
                        let allProducts = []
                        let threeProducts = []
                        if (!isDone && inlineProducts.length > 0) {
                            allProducts = inlineProducts[idx]
                            sortedProducts = inlineProducts[idx]

                            threeProducts = sortedProducts.slice(0, 3).sort((a, b) => (b.aiSummary ? 1 : -1))
                        }
                        return (
                            <>
                            <div ref={chatContainerRef} className={'mt-8'}  key={idx}>
                                {(  showSortAndBudget()) && (
                                    <>
                                        <div >
                                                {idx != 0 && (
                                                    <>
                                                    <hr className="my-4 mx-auto border-t-2 border-mainBorder" />
                                                            <ShareTopic topic={pair[0]} sessionId={props.sessionUUID} isMobile={props.isMobile} />
                                                    </>
                                                )}
                                                {progressMessages.length > 0 &&
                                                    <div className="w-full">

                                                    <ProcessSection progressMessages={progressMessages[idx]} loaderVisible={false}  />

                                                    {/* <SourcesSection reddifRefs={sourceRefs[idx]} videos={videos[idx]} loaderVisible={false} /> */}
                                                    </div>
                                                 }
                                                <ProductCarouselMin
                                                    products={sortedProducts}
                                                    sort={sortType}
                                                    isMobile={props.isMobile}
                                                    handleProductSpecificSearch={handleProductSpecificSearch}
                                                    domains={domainFilter}
                                                    last={ idx === (qapair.length - 1) }
                                                    message={pair[1]}
                                                    idx={idx}
                                                    retryChat={retryChat}
                                                    redditRefs={sourceRefs[idx]}
                                                    setProductDescriptionMap={setProductDescriptionMap}
                                                    setFeedbackOpen={props.setFeedbackOpened}
                                                    sessionId={props.sessionUUID}
                                                    videos={videos[idx]}
                                                    askedQuestions={props.askedQuestions}
                                                    searchQuery={searchQuery}
                                                    object={object}
                                                    gl={cookies[COOKIE_NAME_COUNTRY]}
                                                    session={props.session}
                                                    forceCheckout={props.forceCheckout}
                                                    />
                                                {idx === (qapair.length - 1) && !loaderVisible && questionsList && questionsList.length > 0 && (
                                                    <>
                                                    <FollowUpQuestionCard
                                                        qapair={qapair}
                                                        topic={props.topic}
                                                        questionList={questionsList}
                                                        handleSubmit={submitSearch}
                                                        setAskedQuestions={props.setAskedQuestions}
                                                        askedQuestions={props.askedQuestions}
                                                    />
                                                    </>
                                                )}
                                        </div>
                                    </>
                                )}
                            </div>

                            </>
                        );

                    })
                )
            }
    {(loaderVisible && progressMessages.length > 0) && (
        <div className='pb-8 w-full justify-start'>
            <ProcessSectionProgress loaderVisible={loaderVisible} progressMessages={progressMessages[progressMessages.length -1]} photo={photo} />
        </div>
    )}
            <div style={{ padding: "40px" }}></div>

            <div ref={bottomRef} className="max-w-[900px]"/>

                {!isDone && !loaderVisible && (
                <div className={styles.form} >
                    <div className={`fixed ${!props.isMobile ? '' : 'px-4 md:px-0 lg:px-0' } bottom-0 h-50px flex-1 w-full max-w-[1000px] left-0 md:left-auto lg:left-auto`}>
                        <ChatInputBar
                            query={query}
                            updateQuery={setQuery}
                            qapair={qapair}
                            topic={props.topic}
                            handleSubmit={submitSearch}
                            options={options}
                            open={props.feedbackOpened}
                            setOpen={props.setFeedbackOpened}
                            isMobile={props.isMobile}
                            sessionUUID={props.sessionUUID}
                            inputRef={inputRef}
                            askedQuestions={props.askedQuestions}
                            setAskedQuestions={props.setAskedQuestions}
                        />
                    </div>

                </div>)}
            </div>
        </div>
    );
}
